import pdf from "../assets/files/pitch.pdf"
import {Document, Page, pdfjs} from "react-pdf";
import style from "./style.module.scss"
import {useEffect, useState} from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Presentation2 = () => {

    const [pages, setPages] = useState([])
    useEffect(() => {
        fillPagesArray()
        configurateWidth()
    }, [])


    const configurateWidth = () => {
        window.onresize = () => {
            fillPagesArray()
        }
    }

    const fillPagesArray = () => {
        const pagesArray = []
        for (let i = 1; i < 16; i++) {
            pagesArray.push(<Page pageNumber={i} width={window.innerWidth} className={style.page_wrapper}/>)
        }
        setPages(pagesArray)
    }

    return <div style={{width: window.innerWidth}}>
        <Document file={pdf}>
            {pages}
        </Document>
    </div>
}
export default Presentation2