import React, {useEffect, useRef, useState} from "react";
import styles from "./style.module.scss";
import {IconLock} from "../../assets";
import {ModalForm} from "../ModalForm";
// @ts-ignore
import file from "../pitch-desk/file.pdf";

export const Help = (props: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
      } else {
        videoRef.current.play();
      }
    }
  }, [videoRef]);

  const [wasCopied, setCopied] = useState(false);
  const copyHandler = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopied(true)
  }

  return (
      <section className={styles.help}>
        <div className={styles["help-inner"]}>
          <div className="container">
            {/*<video*/}
            {/*  ref={videoRef}*/}
            {/*  src={video}*/}
            {/*  autoPlay*/}
            {/*  muted*/}
            {/*  loop*/}
            {/*  playsInline*/}
            {/*  controls={false}*/}
            {/*  className={styles.video}*/}
            {/*/>*/}
            {/*<h2 className={styles.title}>Help raise the seed-round:</h2>*/}
            <div className={styles.list}>
              {/*<a href="/" className={clsx(styles["list-item"], styles["list-item--disabled"])} onClick={(e)=>{e.preventDefault()}}>*/}
              {/*  <span>Founder Advisor Standard Template (FAST)</span>*/}
              {/*  /!*<div className={styles["list-item__chip"]}>pdf</div>*!/*/}
              {/*</a>*/}
              {/*<a href="/" className={clsx(styles["list-item"], styles["list-item--disabled"])} onClick={(e)=>{e.preventDefault()}}>*/}
              {/*  <span>Simple agreement for future equity (SAFE)</span>*/}
              {/*  /!*<div className={styles["list-item__chip"]}>pdf</div>*!/*/}
              {/*</a>*/}
              <div className={styles.dataroomWrapper}>
                <button
                    className={styles["list-item"]}
                    onClick={(props.openDataRoom)}
                    id={"open_dataroom"}
                >
                  <span>Data rooM</span>{" "}
                  <IconLock className={styles["list-item__icon"]}/>
                </button>
                <div className={styles.links_wrapper}>
                  <a href={file} target={"_blank"}>
                    Download pitch deck
                  </a>
                  <div onClick={copyHandler}>
                    {wasCopied ? "Copied!" : "Copy link to pitchdeck"}
                  </div>
                </div>
              </div>
            </div>
            <ModalForm open={open} onClose={() => setOpen(false)}/>
        </div>
      </div>
    </section>
  );
};
