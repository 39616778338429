import React, {useEffect, useState} from "react";
import styles from "./style.module.scss"
// @ts-ignore
import file from "./file.pdf"
import clsx from "clsx";

export const PitchDesk = () => {
    const [images, setImages] = useState<string[]>([])
    useEffect(() => {
        (async () => {
            const imageArray: string[] = []
            for (let i = 0; i <= 19; i++) {
                const index = i < 10 ? ("0" + i) : ("" + i)
                const image = await import(`./images/JustDoLuck Pitch Deck 5.0 (NK)_page-00${index}.jpg`)
                imageArray.push(image.default)
            }
            setImages(imageArray)
        })()
    }, [])
    const [index, setIndex] = useState(0)
    const next = () => {
        setIndex((index + 1) % images.length)
    }
    const prev = () => {
        setIndex(((index + images.length) - 1) % images.length)
    }
    return <div className={styles.visions}>
        <div className={clsx("container", styles.no_sidepadding)}>
            <div className={styles["pitch-inner"]}>
                <div className={styles.title}>
                    We are raising invests
                </div>
                <div className={styles.pdf_container}>
                    <img src={images[index]}/>
                    <div className={styles.pagination}>
                        <div onClick={prev} className={styles.prev}> {"<"}--
                        </div>
                        <div>{index + 1}/{images.length}</div>
                        <div onClick={next} className={styles.next}>
                            --{">"}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
}