import React from "react";
import {IconLogo} from "../../assets";
import styles from "./style.module.scss";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles["header-inner"]}>
            <a href="/" className={styles["header__logo"]}>
                <IconLogo/>
            </a>
            <div className={styles.raising}>We are raising investments</div>
          {/* <nav className={styles.nav}>
            <ul className={styles["nav-menu"]}>
              <li className={styles["nav-menu__item"]}>
                <a className={styles["nav-menu__link"]} href="/">
                  eps.
                </a>
              </li>
              <li className={styles["nav-menu__item"]}>
                <a className={clsx(styles["nav-menu__link"])} href="/">
                  jpg.
                </a>
              </li>
              <li className={styles["nav-menu__item"]}>
                <a className={styles["nav-menu__link"]} href="/">
                  png.
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </header>
  );
};
