import React from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import img from "../../assets/image/round.png";

export const About = () => {
  return (
    <div className={styles.about}>
      <div className="container">
        <div className={styles.round}>
          <img src={img} alt="" />
        </div>
        {/*<h2 className={styles.title}>*/}
        {/*  Adding thoughts about what is JUST DO LUCK?*/}
        {/*</h2>*/}
        <ul className={styles.list}>
          {/*<li className={styles["list-item"]}>*/}
          {/*  <b>JDL</b> - capacity magnification factor market environment.*/}
          {/*</li>*/}
          {/*<li className={styles["list-item"]}>*/}
          {/*  <b>JDL</b> - cascading destruction limits of perception*/}
          {/*  accessibility of the world.*/}
          {/*</li>*/}
          {/*<li className={styles["list-item"]}>*/}
          {/*  <b>JDL</b> - is the next big wave of peer-to-peer innovation.*/}
          {/*</li>*/}
          {/*<li className={styles["list-item"]}>*/}
          {/*  <b>JDL</b> - an irreversible change in the evolutionary form of the*/}
          {/*  development of models for the distribution of goods and values.*/}
          {/*</li>*/}
          {/*<li className={styles["list-item"]}>*/}
          {/*  <b>JDL</b> is a phenomenon of attention of large social systems in a*/}
          {/*  "small world".*/}
          {/*</li>*/}
          <li className={styles["list-item"]}>
            <b>Just do luck</b> is order in chaos, where chaos is natural order.
          </li>
        </ul>
      </div>
    </div>
  );
};
