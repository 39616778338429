import React from "react";
import styles from "./style.module.scss";
import {Accordion} from "../accordion/Accordion";
import clsx from "clsx";

export const Visions = () => {
  return (
    <div className={styles.visions}>
      <div className="container">
        <div className={styles["visions-inner"]}>
          <ul className={styles["visions-list"]}>
            <li className={styles["visions-list__item"]}>
              <h3 className={styles.title}>VISION</h3>
              <p className={styles.desc}>
                To earn the trust of every individual on the planet.
              </p>
            </li>
            <li className={styles["visions-list__item"]}>
              <h3 className={styles.title}>GOAL</h3>
              <p className={styles.desc}>
                In 2030, 5 billion people will regularly use JUST DO LUCK APP for entertainment and expanding their
                capabilities.
              </p>
            </li>
            <li className={styles["visions-list__item"]}>
              <h3 className={styles.title}>VALUES</h3>
              <p className={styles.desc}>Simplicity, transparency, honesty, and fairness.</p>
            </li>
            <li className={styles["visions-list__item"]}>
              <h3 className={styles.title}>SLOGAN</h3>
              <p className={styles.desc}>
                Making the impossible possible, the inaccessible accessible, and the complex simple.
              </p>
            </li>
          </ul>
        </div>
        <Accordion title="DECLARATION JUST DO Luck">
          <p className={clsx(styles.text, styles["text-mw"])}>
            The principles of universal equality and justice are fundamental values recognized worldwide and enshrined
            in supreme legal documents. Equality of opportunity and absolute justice here and now is the fundamental
            ideology of Just Do Luck.
          </p>
          <p className={clsx(styles.text, styles["text-mw"])}>
            We aim to leave our mark on human history promoting the online service that mitigates socio-economic
            inequalities by distributing values on the principles of absolute equality of opportunity for all.
          </p>
          <div className={styles["text-list-first"]}>
            <p className={clsx(styles.text)}>
              <b className={styles.blue}>MISSION:</b> Transparency through clear choices, honesty in outcomes, and
              equitable value distribution
              define us
            </p>
            <p className={clsx(styles.text)}>
              <b className={styles.blue}>OBJECTIVE:</b>
              &nbsp;5 billion people regularly use JUST DO LUCK APP for entertainment and expanding their capabilities
              in
              2030.
            </p>
            <p className={clsx(styles.text)}>
              <b className={styles.blue}>CHALLENGES:</b> To earn the trust of every individual on the planet.
            </p>
            <p className={clsx(styles.text)}>
              <b className={styles.blue}>VALUES:</b> Simplicity, transparency, honesty, and fairness.
            </p>
            <p className={clsx(styles.text)}>
              <b className={styles.blue}>SLOGAN:</b> Making the impossible possible, the inaccessible accessible, and
              the complex simple.
            </p>
          </div>

          <ol>
            <p className={styles.text}>
              <b>Collaborating with our partners and clients, we are committed to:</b>
            </p>
            <li>
              <p className={clsx(styles.text)}>
                <b>Changing the World:</b> Creating fair opportunities accessible to everyone with a simple tap.
              </p>
            </li>
            <li>
              <p className={clsx(styles.text)}>
                <b>Redefining Equality:</b> Exercising their inherent right to fair equality, here and now.

              </p>
            </li>
            <li>
              <p className={clsx(styles.text)}>
                <b>Empowering Choices:</b> Ensuring that every individual can make choices, equivalent to those of
                others,
                reflecting their free will and shaping their destiny.
              </p>
            </li>
            <li>
              <p className={clsx(styles.text)}>
                <b>Inspiring Hope:</b> Introducing enjoyable entertainment that sparks hope and finds the extraordinary
                in
                ordinary, simple choices.
              </p>
            </li>
            <li>
              <p className={clsx(styles.text)}>
                <b>Creating Something Meaningful:</b> Developing a friendly platform that connects markets and clients
                in
                innovative ways.
              </p>
            </li>
          </ol>
          <div className={styles["text-bottom"]}>
            Team Just Do Luck, October, 2023
          </div>
        </Accordion>
      </div>
    </div>
  );
};
