import React from "react";
import styles from "./style.module.scss";

export const Banner = () => {
  return (
    <div className={styles.banner}>
      <div className="container">
        <div className={styles["banner-inner"]}>
          <div className={styles["banner-half"]}>
            <h1 className={styles.title}>MISSION</h1>
            <p className={styles.subtitle}>
              Transparency through clear choices, honesty in outcomes, and equitable value distribution define us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
