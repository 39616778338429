import React, {useEffect, useState} from "react";
import {About} from "./components/About";

import {Banner} from "./components/banner";
import {DataRoom} from "./components/DataRoom";
import {Footer} from "./components/footer/Footer";
import {Header} from "./components/header";
import {Help} from "./components/Help";
import {Links} from "./components/Links";
import {Team} from "./components/Team";
import {Visions} from "./components/Visions";
import {PitchDesk} from "./components/pitch-desk";

function LandingPage() {
    const password = "5zvMI3KTS2"
    const [isOpenDataRoom, setOpenDataRoom] = useState(false)
    const openDataRoom = () => {
        const pass = prompt("Enter access password")
        localStorage.removeItem("showed");

        if (pass === password) {
            setOpenDataRoom(true);
            setTimeout(() => {
                window.scrollTo({top: 999999, behavior: 'smooth'});
            }, 500)
        }
    }
    useEffect(()=>{
        console.log("here")
        let data = localStorage.getItem("showed");
        console.log(data)
        if (!data && window.location.href.includes("open_dataroom")){
            openDataRoom();
        }
        localStorage.setItem("showed", "true")

    }, [])
    return (
        <div className="wrapper">
            <Header/>
            <PitchDesk/>
            <Help openDataRoom={openDataRoom}/>
            <Banner/>
            <Visions/>
            <Links/>
            <Team/>
            <About/>
            {isOpenDataRoom && <DataRoom/>}
            <Footer/>
        </div>
    );
}

export default LandingPage;
