import React, { useState, FC, ReactNode, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { IconArrow } from "../../assets";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "./styles.module.scss";
// @ts-ignore
import pdf from "../../../src/assets/pdf/JUST DO LUCK declaration.pdf"
type Props = {
  title: string;
  children: ReactNode;
};

export const Accordion: FC<Props> = ({ title, children }: Props) => {
  const [open, setOpen] = useState(false);
  const size = useWindowSize();
  const tab = size < 992 && size > 576;
  const mob = size < 576;
  const ref = useRef<HTMLDivElement>(null);
  const height = tab ? "120px" : mob ? "82px" : "196px";

  const toggleHandler = (e: any) => {
    setOpen(!open);
    if (open) {
      scrollto();
    }
  };

  const scrollto = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const openAnimation = useSpring({
    from: { opacity: 0, maxHeight: height },
    to: { opacity: 1, maxHeight: open ? "5000px" : height },
  });

  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: open ? "rotate(0deg)" : "rotate(180deg)",
    },
  });

  const clickHandler = (e:any)=>{e.stopPropagation()}

  return (
    <animated.div className={styled.accordion__item} style={openAnimation}>
      <span className={styled.accordion__scroll} ref={ref}></span>
      <div className={styled.accordion__header} onClick={toggleHandler}>
        <h4 className={styled.accordion__title}>{title}</h4>
        <animated.i style={iconAnimation}>
          <IconArrow className={styled.accordion__icon} />
        </animated.i>
        <a target={"_blank"} href={pdf} onClick={clickHandler}><span className={styled["accordion__chip"]}>PDF</span></a>
      </div>
      <div className={styled.accordion__content}>
        {children}
        <span className={styled["accordion__hide"]} onClick={toggleHandler}>
          <IconArrow />
        </span>
      </div>
    </animated.div>
  );
};
