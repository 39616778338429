import { useState, useLayoutEffect } from "react";

export default function useWindowSize(): number {
  const [windowSize, setWindowSize] = useState(1600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
