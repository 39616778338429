import React, {useState} from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import { IconArrowLink, IconLogo } from "../../assets";
import YoutubeModal from "../ytb-modal/YoutubeModal";
import plan1 from "../../assets/image/plan1.png"
import plan2 from "../../assets/image/plan2.png"
export const DataRoom = () => {

  const [ytb, setYtb] = useState(false);
  const link = "https://www.youtube.com/embed/YrhHp_g9g2k"

  return (
    <div id="dataroom">
      <YoutubeModal isOpen={ytb} setOpen={setYtb} link={link}/>

      <div className={styles.header}>
        <div className="container">
          <div className={styles["header-inner"]}>
            <div className={styles.header__title}>Data Room</div>
            <IconLogo className={styles.header__logo} />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className="container">
          <h3 className={styles.head}>Data Room</h3>
          <div className={styles["container-inner"]}>
            <div className={styles.table}>
              <div className={clsx(styles.row, styles.header)}>
                <div className={styles.cell}>№</div>
                <div className={styles.cell}>
                  name. <div className={styles.mob}> / file. / link.</div>
                </div>
                <div className={styles.cell}>file / link.</div>
                <div className={styles.cell}>date.</div>
              </div>

              <div className={styles.row}>
                <div className={styles.cell}>1</div>
                <div className={styles.cell}>
                  First video (2018)
                  <div className={styles.mob}>
                    <a href="#" onClick={()=>{setYtb(true)}}>link</a>
                  </div>
                </div>
                <div className={styles.cell}>
                  <a href="#" onClick={()=>{setYtb(true)}}>link</a>
                </div>
                <div className={styles.cell}>11/15/2022</div>
              </div>

              <div className={styles.row}>
                <div className={styles.cell}>2</div>
                <div className={styles.cell}>
                  Frequently Asked Questions
                  <div className={styles.mob}>
                    <a  target="_blank" href="https://slbl.notion.site/Questions-and-Answers-JustDoLuck-be8e74e651954013b34732034de0ad09">link</a>
                  </div>
                </div>
                <div className={styles.cell}>
                  <a target="_blank" href="https://slbl.notion.site/Questions-and-Answers-JustDoLuck-be8e74e651954013b34732034de0ad09">link</a>
                </div>
                <div className={styles.cell}>11/12/2022</div>
              </div>

              <div className={styles.row}>
                <div className={styles.cell}>3</div>
                <div className={styles.cell}>
                  Technology strategy
                  <div className={styles.mob}>
                    <a target={"_blank"} href={plan1}>image1,</a> <a target={"_blank"} href={plan2}>image2</a>
                  </div>
                </div>
                <div className={styles.cell}>
                  <a target={"_blank"} href={plan1}>image1,</a> <a target={"_blank"} href={plan2}>image2</a>
                </div>
                <div className={styles.cell}>11/19/2022</div>
              </div>

              <div className={styles.row}>
                <div className={styles.cell}>4</div>
                <div className={styles.cell}>
                  Business plan
                  <div className={styles.mob}>
                    <a href="mailto:official@justdoluck.com">On request</a>
                  </div>
                </div>
                <div className={styles.cell}>
                  <a href="mailto:official@justdoluck.com">On request</a>
                </div>
                <div className={styles.cell}>12/11/2022</div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>5</div>
                <div className={styles.cell}>
                  Financial model
                  <div className={styles.mob}>
                    <a href="mailto:official@justdoluck.com">On request</a>
                  </div>
                </div>
                <div className={styles.cell}>
                  <a href="mailto:official@justdoluck.com">On request</a>
                </div>
                <div className={styles.cell}>12/11/2022</div>
              </div>
            </div>
            <div className={styles.links}>
              <h4 className={styles.links__title}>Metrics.</h4>
              <div className={styles["links-inner"]}>
                <div className={styles.half}>
                  <div className={styles.links__item}>
                    <a href="/">
                      DAU (Daily Active Users) <IconArrowLink />
                    </a>
                    <a href="/">
                      WAU (Weekly Active Users)
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      MAU (Monthly Active Users)
                      <IconArrowLink />
                    </a>
                  </div>
                  <div className={styles.links__item}>
                    <a href="/">
                      PCCU (Peak concurrent Users)
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      CCU (Concurrent Users)
                      <IconArrowLink />
                    </a>
                  </div>
                </div>
                <div className={styles.half}>
                  <div className={styles.links__item}>
                    <a href="/">
                      0-Day Retention
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      1-Day Retention
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      7-Day Retention
                      <IconArrowLink />
                    </a>
                  </div>
                  <div className={styles.links__item}>
                    <a href="/">
                      ASL (Average Session Length)
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      TDPT (Total Daile Play Time)
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      Sticky Factor
                      <IconArrowLink />
                    </a>
                    <a href="/">
                      Referral Factor
                      <IconArrowLink />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
