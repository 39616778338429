import React from "react";
import {IconCopyring} from "../../assets";
import styles from "./style.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className="container">
            <div className={styles.copyring}>
                <IconCopyring/> {new Date().getFullYear()} - Just do luck, Inc.
            </div>
            <div className={styles.law_address}>
                3876 Quarter Mile Drive, CA 92130, USA
            </div>
        </div>
    </footer>
  );
};
