import React, {useState} from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import {IconArrowLink, IconVideo} from "../../assets";
import YoutubeModal from "../ytb-modal/YoutubeModal";

export const Links = () => {
    const [ytb1, setYtb1] = useState(false);
    const link1 = "https://www.youtube.com/embed/gJ_BB38zMBA"

    const [ytb2, setYtb2] = useState(false);
    const link2 = "https://www.youtube.com/embed/reB9Au5yW3c"

    return (
        <section>
            {ytb1 && <YoutubeModal isOpen={ytb1} setOpen={setYtb1} link={link1}/>}
            {ytb2 && <YoutubeModal isOpen={ytb2} setOpen={setYtb2} link={link2}/>}

            <div className="container">
                <div className={styles["links-boxes"]}>
                    <a href="https://justdoluck.com" className={styles["links-box"]}>
                        <p className={styles["links-box__title"]}>JDL Gaming Platform</p>
                        <span className={styles["links-box__icon"]}>
              <IconArrowLink/>
            </span>
                        <p className={styles["links-box__link"]}>justdoluck.com</p>
                    </a>
                    <a href="https://algorithm.justdoluck.com" className={styles["links-box"]}>
                        <p className={styles["links-box__title"]}>JDL Algorithm</p>
                        <span className={styles["links-box__icon"]}>
              <IconArrowLink/>
            </span>
                        <p className={styles["links-box__link"]}>
                            algorithm.justdoluck.com{" "}
                        </p>
                    </a>
                    <a href="https://justdoluck.me" className={styles["links-box"]}>
                        <p className={styles["links-box__title"]}>For Users</p>
                        <span className={styles["links-box__icon"]}>
              <IconArrowLink/>
            </span>
                        <p className={styles["links-box__link"]}>justdoluck.me</p>
                    </a>
                    <a href="https://justdoluck.club" className={styles["links-box"]}>
                        <p className={styles["links-box__title"]}>For Clients</p>
                        <span className={styles["links-box__icon"]}>
              <IconArrowLink/>
            </span>
                        <p className={styles["links-box__link"]}>justdoluck.club</p>
                    </a>
                </div>
                <div className={styles["links-bottom"]}>
                    <a href="https://www.facebook.com/justdoluck" className={styles["links-bottom__item"]}>
                        <span>Facebook</span>
                        <IconArrowLink/>
                    </a>
                    <a href="https://twitter.com/justdoluck" className={styles["links-bottom__item"]}>
                        <span>Twitter</span>
                        <IconArrowLink/>
                    </a>
                    <a href="https://t.me/justdoluck" className={styles["links-bottom__item"]}>
                        <span>Telegram</span>
                        <IconArrowLink/>
                    </a>
                    <a href="https://www.instagram.com/justdoluckworld/" className={clsx(styles["links-bottom__item"])}
                    >
                        <span>Instagram</span>
                        <IconArrowLink/>
                    </a>
                    <a
                        href="/"
                        className={clsx(styles["links-bottom__item"], styles.disabled)}
                    >
                        <span>Discord</span>
                        <IconArrowLink/>
                    </a>
                    <a
                        href="/"
                        className={clsx(styles["links-bottom__item"], styles.disabled)}
                    >
                        <span>Reddit</span>
                        <IconArrowLink/>
                    </a>
                </div>
                <ul className={styles["list"]}>
                    <li className={styles["list-item"]}>
                        <a
                            href="mailto:official@justdoluck.com"
                            className={styles["list-link"]}
                        >
                            <span>official@justdoluck.com</span>
                        </a>
                    </li>
                    <li className={clsx(styles["list-item"], styles["list-item--disabled"])}>
                        <a href="/" className={styles["list-link"]}>
                            <span>AngelList</span>
                            {/*<IconArrowLink />*/}
                        </a>
                    </li>
                    <li className={clsx(styles["list-item"], styles["list-item--disabled"])}>
                        <a href="/" className={styles["list-link"]}>
                            <span>Crunchbase</span>
                            {/*<IconArrowLink />*/}
                        </a>
                    </li>
                    {/*<li className={styles["list-item"]}>*/}
                    {/*  <a*/}
                    {/*    href="/"*/}
                    {/*    className={clsx(styles["list-link"], styles["list-link--small"])}*/}
                    {/*  >*/}
                    {/*    <span>First video</span> <IconVideo />*/}
                    {/*  </a>*/}
                    {/*</li>*/}

                    <li className={clsx(styles["list-item"])}>
                        <a
                            href="#"
                            className={clsx(styles["list-link"], styles["list-link--small"])}
                            onClick={()=>{setYtb2(true)}}
                        >
                            <span>Presentation for Client`s</span>
                            <IconVideo/>
                        </a>
                    </li>
                    <li className={clsx(styles["list-item"])}>
                        <a
                            href="#"
                            className={clsx(styles["list-link"], styles["list-link--small"])}
                            onClick={()=>{setYtb1(true)}}
                        >
                            <span>How to play</span>
                            <IconVideo/>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
};
