import React, { FC } from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import { Modal } from "../modal/Modal";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ModalForm: FC<Props> = ({ open, onClose }) => {
  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    const anchor = document.querySelector("#dataroom");
    anchor!.scrollIntoView({ behavior: "smooth", block: "start" });
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modal}>
        <h3 className={styles.title}>Log in data room</h3>
        <p className={styles.subtitle}>
          If you do not have a password and login, but you are interested our
          business, please contact us by
          <a href="mailto:justdoluck@gmail.com" className={styles.link}>
            justdoluck@gmail.com
          </a>
        </p>
        <form onSubmit={onSubmit}>
          <div className={styles["form-inner"]}>
            <input type="text" className={styles.field} placeholder="login" />
            <input
              type="text"
              className={styles.field}
              placeholder="password"
            />
            <button className={styles.button} type="submit">
              Log in
            </button>
            <a href="/" className={styles.forgot}>
              Forgot your password?
            </a>
          </div>
        </form>
      </div>
    </Modal>
  );
};
