import React from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import img from "../../assets/image/team/1.jpg";
import img2 from "../../assets/image/team/2.jpg";
import img3 from "../../assets/image/team/3.jpg";
import img4 from "../../assets/image/team/4.jpg";
import img5 from "../../assets/image/team/5.jpg";
import img6 from "../../assets/image/team/6.jpg";
import img7 from "../../assets/image/team/7.jpg";

import { IconLinked, IconMail } from "../../assets";

export const Team = () => {
  return (
    <section className={styles.team}>
      <div className="container">
        <h2 className={styles.title}>team</h2>
      </div>
      <div className={styles.container}>
        <div className={styles["team-list"]}>
          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Artem Nilov </h4>
                <div className={styles.social}>
                  <a href="https://www.linkedin.com/in/artem-nilov" className={styles["social-item"]} target={"_blank"}>
                    <IconLinked />
                  </a>
                  <a href="mailto:justdoluck@gmail.com" className={styles["social-item"]} target={"_blank"}>
                    <IconMail />
                  </a>
                </div>
              </div>
              <div className={styles["card__position"]}>CEO</div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img2} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Slava Belykh</h4>
                <div className={styles.social}>
                  <a href="https://www.linkedin.com/in/slavabelykh/" className={styles["social-item"]} target={"_blank"}>
                    <IconLinked />
                  </a>
                  <a href="mailto:slava.belykh@gmail.com" className={styles["social-item"]} target={"_blank"}>
                    <IconMail />
                  </a>
                </div>
              </div>
              <div className={styles["card__position"]}>СPO</div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img3} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Evgeniy Kislov</h4>
                <div className={styles.social}></div>
              </div>
              <div className={styles["card__position"]}>CTO</div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img4} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Ekaterina Thukvatullina</h4>
                <div className={styles.social}></div>
              </div>
              <div className={styles["card__position"]}>UX/UI designer</div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img5} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Anton Marchuk</h4>
                <div className={styles.social}></div>
              </div>
              <div className={styles["card__position"]}>
                Lead frontend developer
              </div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img6} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Vitaly Semenov</h4>
                <div className={styles.social}></div>
              </div>
              <div className={styles["card__position"]}>DevOps</div>
            </div>
          </div>

          <div className={styles["team-list__item"]}>
            <div className={styles.card}>
              <img src={img7} alt="" />
              <div className={styles["card-head"]}>
                <h4 className={styles["card__name"]}>Adam Martanov</h4>
                <div className={styles.social}></div>
              </div>
              <div className={styles["card__position"]}>Sales</div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};
